import styled from 'styled-components';
export const TextWrapper = styled.div `
  padding-bottom: 55px;
  ${(props) => props.theme.mixins.mobile('padding-bottom: 30px;')}
`;
export const TextContent = styled.div `
  margin: 0;
  font-size: 16px;
  line-height: 26px;
  @media screen and (max-width: 960px) {
    font-size: 14px;
    line-height: 23px;
  }
`;
