import React from 'react';
import { useLang } from '@@Hooks/useLang';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { IconDefs } from '@@Components/Icon/IconDefs';
import { Notifications } from '@@Components/Notifications';
import { Header } from '@@Components/Header';
import { Footer } from '@@Components/Footer';
import { dispatch } from '@@Redux/dispatch';
import { setIsMinicartOpened } from '@@Redux/actions/minicart.actions';
import { usePageScroll } from '@@Hooks/usePageScroll';
const ScrollContainer = styled.div `
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
`;
const PageContainer = styled.div `
  position: relative;
`;
const MainContainer = styled.div `
  margin-top: var(--header-mobile-height);
  position: relative;
  width: 100%;
  min-height: calc(100vh - 327px);

  @media (min-width: 883px) {
    margin-top: 0;
  }
`;
export const Layout = (props) => {
    const { language } = useLang();
    usePageScroll(() => dispatch(setIsMinicartOpened(false)), {
        ignoredRoutes: ['/checkout/success'],
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("html", { lang: language.slice(0, 2) }),
            React.createElement("meta", { name: "viewport", content: "width=device-width, initial-scale=1.0" })),
        React.createElement(IconDefs, null),
        React.createElement(ScrollContainer, { id: "layout-container" },
            React.createElement(PageContainer, null,
                React.createElement(Header, null),
                React.createElement(MainContainer, null, props.children),
                React.createElement(Notifications, null),
                React.createElement(Footer, null)))));
};
