import Input from '@@Components/Input/Input';
import { useLang } from '@@Hooks/useLang';
import React, { useState } from 'react';
import FormRowsWrapper from '@@Components/FormRowsWrapper';
import { useControlledForm, } from '@@Hooks/useControlledForm';
import { validateEmail, validatePhoneNumber } from '@@Helpers/validators';
import { Checkbox } from '@@Components/Input/Checkbox';
import { Textarea } from '@@Components/Textarea';
import { Subtitle } from '../styled';
import { InputsWrapper, OptionalRegistrationCheckboxWrapper, UserDataFormWrapper, } from './styles';
export const CheckoutPageForm = React.forwardRef(({ onChange = () => { } }, ref) => {
    const { translate } = useLang();
    const [showValidationForced, setShowValidationForced] = useState(true);
    const [shouldDisableFields, setShouldDisableFields] = useState(false);
    const forceValidationShow = () => {
        setShowValidationForced(true);
    };
    const { register, control, formWatch, formState, errors, setValue, handleSubmit, trigger, clearErrors, isValid, reset, } = useControlledForm({
        mode: 'onChange',
        onChange,
        additionalRefMethods: {
            triggerShowValidation: () => {
                forceValidationShow();
                ref.current.trigger();
            },
        },
    }, ref);
    const lang = {
        name: translate('checkout.name'),
        surname: translate('checkout.surname'),
        email: translate('checkout.email'),
        phone: translate('checkout.phone'),
        commentary: translate('checkout.commentary'),
        terms: translate('checkout.terms'),
        termsArray: translate('checkout.terms').split(' '),
        enter: translate('checkout.enter'),
    };
    return (React.createElement(UserDataFormWrapper, null,
        React.createElement("form", { noValidate: true, autoComplete: "off", onSubmit: handleSubmit(() => { }) },
            React.createElement(InputsWrapper, null,
                React.createElement(Subtitle, null, lang.data),
                React.createElement(FormRowsWrapper, null,
                    React.createElement("div", { className: "form-row" },
                        React.createElement(Input, { disabled: shouldDisableFields, required: true, name: "firstName", label: translate('checkout.name'), placeholder: '*' + translate('checkout.name'), error: errors.firstName, ref: register({ required: translate('validation.required') }) })),
                    React.createElement("div", { className: "form-row" },
                        React.createElement(Input, { disabled: shouldDisableFields, required: true, name: "lastName", label: translate('checkout.surname'), placeholder: '*' + translate('checkout.surname'), error: errors.lastName, ref: register({ required: translate('validation.required') }) })),
                    React.createElement("div", { className: "form-row" },
                        React.createElement(Input, { disabled: shouldDisableFields, required: true, name: "phoneNumber", type: "number", error: errors.phoneNumber, label: translate('checkout.phone'), placeholder: '*' + translate('checkout.phone'), ref: register({ validate: validatePhoneNumber }) })),
                    React.createElement("div", { className: "form-row" },
                        React.createElement(Input, { disabled: shouldDisableFields, required: true, name: "emailAddress", label: translate('checkout.email'), placeholder: '*' + translate('checkout.email'), error: errors.emailAddress, ref: register({ validate: validateEmail }) })),
                    React.createElement(OptionalRegistrationCheckboxWrapper, null,
                        React.createElement(Checkbox, { required: true, error: errors['terms'], name: 'terms', ref: register({ required: translate('validation.required') }) }, lang.termsArray.length > 2 && (React.createElement(React.Fragment, null,
                            `${lang.termsArray[0]} `,
                            React.createElement("a", { href: "/regulamin", target: "_blank" }, lang.termsArray[1]),
                            ' ',
                            ` ${lang.termsArray.slice(2).join(' ')}`)))))),
            React.createElement(InputsWrapper, null,
                React.createElement(Subtitle, null, lang.commentary),
                React.createElement(Textarea, { maxLength: 200, placeholder: `${lang.enter} ${lang.commentary}`, label: lang.commentary, disableResize: true, name: 'comment', ref: register({ required: false }) })))));
});
