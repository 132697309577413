import styled from 'styled-components';
export const CategoryResultsManageBarWrapper = styled.div `
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  ${(props) => props.theme.mixins.mobile('padding-top: 15px;')};
`;
export const Text = styled.p `
  font-size: 14px;
  display: flex;
  align-items: center;
  margin: 0;
`;
export const TextPadding = styled(Text) `
  padding-right: 5px;
  ${(props) => props.theme.mixins.mobile('display: none')};
`;
export const TextMobile = styled.button `
  ${(props) => props.theme.mixins.mobile('display: flex;')};
  margin-right: ${(props) => props.marginRight};
  display: none;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  font-weight: 700;
`;
export const PaginationWrapper = styled.div `
  @media (max-width: 550px) {
    display: none;
  }
`;
export const ProductsInfoWrapper = styled.div `
  width: 220px;
  ${(props) => props.theme.mixins.mobile('width: 190px;')};
  @media (max-width: 550px) {
    width: auto;
  }
`;
export const DropdownContainer = styled.div `
  display: flex;
  justify-content: flex-end;
  width: 220px;
  ${(props) => props.theme.mixins.mobile('width: 190px;')};
  @media (max-width: 550px) {
    width: auto;
  }
`;
export const DropdownWrapper = styled.div `
  display: flex;
  align-items: center;
  ${(props) => props.theme.mixins.mobile('display: none')};
`;
export const IconWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`;
