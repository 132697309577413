import styled from 'styled-components';
export const StaticPageContent = styled.div `
  max-width: 1280px;
  margin: auto;
  padding: 30px 50px;
  ${(props) => props.theme.mixins.mobile(`
    padding: 30px 15px;
  `)};

  form {
    max-width: 670px;
    margin: 0 auto;
  }

  a {
    font-weight: 600;
    padding-bottom: 2px;
    border-bottom: 1px solid #292220;
    text-decoration: none;
  }

  .not-underlined {
    border-bottom: none;
  }

  a button {
    background: #fff;
    border: 1px solid #525252;
    min-width: 250px;
    text-align: center;
    font-size: 14px;
    font-style: italic;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    line-height: 24px;
    padding: 8px 12px;
    &:hover {
      border-color: #d9000d;
    }
    &.full {
      background: #000;
      color: #fff;
      &:hover {
        background-color: #d9000d;
      }
    }
    &:active {
      position: relative;
      top: 2px;
    }
  }

  ul,
  ol {
    margin: unset;
    padding: unset;
    list-style-position: inside;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  img {
    max-width: 100%;
  }

  @media screen and (max-width: 600px) {
    iframe {
      /* Default style before wrapper is loaded - fix on mobile */
      width: 100%;
      height: auto;
      aspect-ratio: 16 / 9;
    }
  }

  .video-wrapper {
    position: relative;

    &:not([data-reused]) {
      margin: 20px 0;
    }

    &:after {
      content: ' ';
      padding-bottom: calc(var(--aspect-ratio, 0.5625) * 100%); /* 16:9 */
      display: block;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      aspect-ratio: none;
    }
  }

  table {
    width: 100% !important;
    display: block !important;
    height: auto !important;
    overflow-x: auto !important;
    padding-bottom: 20px;

    tbody {
      display: table !important;
      width: 100% !important;
      height: auto !important;
      min-width: 800px !important;
      border: 1px solid #000;
      border-collapse: collapse !important;
    }

    td,
    th {
      border: 1px solid #000;
      padding: 5px;
    }
  }
`;
