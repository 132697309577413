import styled from 'styled-components';
export const ProductsListContainer = styled.div `
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const ProductsListInnerContainer = styled.div `
  display: flex;
  flex-direction: column;
`;
