import React from 'react';
import { NavigationButtonLeft, NavigationButtonRight, } from '@@Components/BannersSlider/styled';
import Icon from '@@Components/Icon/Icon';
export const BannerSliderNavigator = ({ count = 0, swiperInstance }) => {
    return (count > 1 && (React.createElement(React.Fragment, null,
        React.createElement(NavigationButtonLeft, { "aria-label": "Prev slide", className: "prev_slide", onClick: () => swiperInstance.slidePrev() },
            React.createElement(Icon, { icon: "prev_slide", width: 18 })),
        React.createElement(NavigationButtonRight, { "aria-label": "Next slide", className: "next_slide", onClick: () => swiperInstance.slideNext() },
            React.createElement(Icon, { icon: "next_slide", width: 18 })))));
};
