import styled from 'styled-components';
export const UserDataFormWrapper = styled.div ``;
export const OptionalRegistrationCheckboxWrapper = styled.div `
  margin: 26px 0;
`;
export const TwoFieldsWrapper = styled.div `
  ${(props) => !props.single &&
    `
    display: grid;
    grid-template-columns: 1fr 210px;
    grid-gap: var(--form-horizontal-gap);

    @media screen and (max-width: 900px) {
      display: block;

      > div + div {
        margin-top: var(--form-vertical-gap);
      }
    }
  `}
`;
export const InputsWrapper = styled.div `
  width: 100%;
`;
