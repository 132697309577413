import styled from 'styled-components';
import React from 'react';
const FooterHeading = styled.p `
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  line-height: 1.85rem;
  font-weight: 700;
  ${(props) => props.theme.mixins.mobile(`
    font-size: 16px;
  `)}
`;
const BrandHeading = styled.h5 `
  text-align: left;
  font-size: 28px;
  line-height: 34px;
  font-weight: bold;
  margin-bottom: 45px;
`;
export const Heading = (props) => {
    switch (props.type) {
        case 'footer':
            return React.createElement(FooterHeading, null, props.content);
        case 'brand':
            return React.createElement(BrandHeading, null, props.content);
        default:
            return React.createElement("p", null, props.content);
    }
};
