import React from 'react';
import Icon from '@@Components/Icon/Icon';
import { ButtonContent, DownloadButton, DownloadButtonsWrapper, } from '@@Components/DownloadDocumentsButtons/styled';
export const DownloadDocumentsButtons = (props) => {
    var _a, _b;
    return (React.createElement(DownloadButtonsWrapper, null, (_b = (_a = props.documents) === null || _a === void 0 ? void 0 : _a.sort((el1, el2) => el1.priority - el2.priority)) === null || _b === void 0 ? void 0 : _b.map((document, index) => {
        return (React.createElement("a", { href: document.file, target: "_blank", key: index, rel: "noreferrer" },
            React.createElement(DownloadButton, null,
                React.createElement(Icon, { icon: "download", width: 26 }),
                React.createElement(ButtonContent, null, document.title))));
    })));
};
