import styled from 'styled-components';
export const FormWrapper = styled.div ``;
export const FormTitle = styled.h4 `
  margin-bottom: 25px;
`;
export const InnerFormWrapper = styled.div `
  > *:not(:last-child) {
    margin-bottom: 10px;
  }
  margin-bottom: 35px;
  ${(props) => props.theme.mixins.mobile('margin-bottom: 30px;')}
  @media (max-width: 768px) {
    > *:nth-child(2) {
      margin-bottom: 20px;
    }
  }
`;
export const ButtonWrapper = styled.div `
  max-width: 250px;
  ${(props) => props.theme.mixins.mobile('max-width: 100%')}
`;
export const DatesWrapper = styled.div `
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;
