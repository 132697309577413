import { Checkbox } from '@kmk/components/src/Input/Checkbox';
import React, { useEffect, useState } from 'react';
import { PriceUtils } from '@@Storefront';
import { useLang } from '@@Hooks/useLang';
import { useSelector } from 'react-redux';
import { productSelector } from '@@Common/redux/selectors/product.selectors';
import { AccesoryGroupName, AccesoryGroupOptions, AccesoryGroupPrice, AccessoryGroupCheckboxWrapper, AccessoryGroupOptionWrapper, AccessoryGroupSection, AccessoryGroupTitle, AccessoryGroupsContainer, AccessoryGroupInnerWrapper, } from './styled';
export const RentalAccessories = ({ onChange, differentPlace }) => {
    var _a, _b;
    const [selectedOptions, setSelectedOptions] = useState([]);
    const { current: currentProduct } = useSelector(productSelector);
    const { translate } = useLang();
    const bundleProductOptions = (_b = (_a = currentProduct.bundle) === null || _a === void 0 ? void 0 : _a.extension_attributes) === null || _b === void 0 ? void 0 : _b.bundle_product_options;
    const mapSelectedOptionsToBundleOptions = (selectedOptions) => {
        return selectedOptions.reduce((acc, curr) => {
            const foundGroup = acc.find((item) => item.option_id === curr.option_id);
            let newAcc = acc;
            if (!foundGroup) {
                newAcc = [
                    ...acc,
                    {
                        option_id: curr.option_id,
                        option_qty: 1,
                        option_selections: [curr.selection_id],
                    },
                ];
            }
            else {
                foundGroup.option_selections = [
                    ...foundGroup.option_selections,
                    curr.selection_id,
                ];
            }
            return newAcc;
        }, []);
    };
    const isOptionSelected = (optionId, selectionId) => {
        return !!selectedOptions.find((option) => option.option_id === optionId && option.selection_id === selectionId);
    };
    const toggleOptionSelected = (optionId, selectionId, option) => {
        const shouldBeSelected = typeof option === 'boolean'
            ? option
            : !isOptionSelected(optionId, selectionId);
        if (shouldBeSelected) {
            setSelectedOptions((selectedOptions) => [
                ...selectedOptions,
                { option_id: optionId, selection_id: selectionId },
            ]);
        }
        else {
            setSelectedOptions((selectedOptions) => selectedOptions.filter((option) => option.option_id !== optionId ||
                option.selection_id !== selectionId));
        }
        return true;
    };
    useEffect(() => {
        if (onChange && typeof onChange === 'function') {
            onChange([...mapSelectedOptionsToBundleOptions(selectedOptions)]);
        }
    }, [selectedOptions]);
    useEffect(() => {
        var _a;
        const reservationOption = bundleProductOptions === null || bundleProductOptions === void 0 ? void 0 : bundleProductOptions.find((option) => ['reservation_options', 'reservation_options_bike'].includes(option.title));
        const reservationDifferentPlaceSelection = (_a = reservationOption === null || reservationOption === void 0 ? void 0 : reservationOption.product_links) === null || _a === void 0 ? void 0 : _a.find((link) => link.sku === 'zwrot_inne_miejsce');
        const differentPlaceOptionId = reservationDifferentPlaceSelection === null || reservationDifferentPlaceSelection === void 0 ? void 0 : reservationDifferentPlaceSelection.option_id;
        const differentPlaceSelectionId = Number(reservationDifferentPlaceSelection === null || reservationDifferentPlaceSelection === void 0 ? void 0 : reservationDifferentPlaceSelection.id);
        toggleOptionSelected(differentPlaceOptionId, differentPlaceSelectionId, differentPlace);
    }, [differentPlace]);
    return (React.createElement("div", null, (bundleProductOptions === null || bundleProductOptions === void 0 ? void 0 : bundleProductOptions.length) > 0 && (React.createElement(AccessoryGroupsContainer, { id: "accessory" }, bundleProductOptions
        .filter((option) => { var _a; return !['motor', 'bike'].includes((_a = option.title) === null || _a === void 0 ? void 0 : _a.toLowerCase()); })
        .map((option) => (React.createElement(AccessoryGroupSection, { key: option.option_id },
        React.createElement(AccessoryGroupTitle, null, translate(`headers.${option.title}`)),
        React.createElement(AccesoryGroupOptions, null, option.product_links.map((link) => {
            var _a;
            return (React.createElement(AccessoryGroupOptionWrapper, { key: link.id, onClick: () => !(link.sku === 'zwrot_inne_miejsce') &&
                    toggleOptionSelected(link.option_id, Number(link.id)), disabled: link.sku === 'zwrot_inne_miejsce' },
                React.createElement(AccessoryGroupCheckboxWrapper, null,
                    React.createElement(Checkbox, { checked: isOptionSelected(link.option_id, Number(link.id)), disabled: link.sku === 'zwrot_inne_miejsce' })),
                React.createElement(AccessoryGroupInnerWrapper, null,
                    React.createElement(AccesoryGroupName, null, ((_a = link.extension_attributes) === null || _a === void 0 ? void 0 : _a.item_product_name) ||
                        link.sku),
                    link.price && (React.createElement(AccesoryGroupPrice, null,
                        PriceUtils.getFormattedPrice(link.price).price,
                        ' ',
                        option.title.toLowerCase() ===
                            'additional_items' &&
                            `/ ${translate('common.day_2')}`)))));
        })))))))));
};
