import styled from 'styled-components';
export const FaqColumnContainer = styled.div `
  display: flex;
  flex-direction: column;
  width: 555px;
  @media (max-width: 1200px) {
    width: 50%;
  }
  ${(props) => props.theme.mixins.mobile(`
    width: 100%;
    padding-bottom: 15px;
  `)}
`;
export const Title = styled.p `
  margin-bottom: 15px;
  font-size: 1.71rem;
  line-height: 2.42rem;
  font-weight: 700;
  ${(props) => props.theme.mixins.mobile(`
    font-size: 16px;
    margin-bottom: 0;
  `)}
`;
export const PostsContainer = styled.div `
  display: flex;
  flex-direction: column;
  > :last-child {
    border: none;
  }
`;
