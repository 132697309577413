import styled from 'styled-components';
import { Button } from '@@Components/Buttons/Button';
export const DrawerContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow-y: scroll;
`;
export const FiltersContainer = styled.div `
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 15px;
  padding-top: 3px;
  width: 950px;
  @media (max-width: 1439px) {
    width: 66vw;
  }
  @media (max-width: 1280px) {
    width: 60vw;
  }
  height: 100%;
`;
export const SelectWrapper = styled.div `
  margin-right: 10px;
  margin-bottom: 20px;
  min-width: 207px;
  max-width: 300px;
`;
export const FiltersMobileContainer = styled.div `
  display: flex;
  flex-direction: column;
`;
export const HeaderDiv = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 10px 0;
  box-shadow: 0px 5px 20px #0000000f;
  margin-bottom: 10px;
  position: sticky;
  z-index: 50;
  background-color: #fff;
  width: 100%;
  top: 0;
  height: 40px;
  white-space: nowrap;
`;
export const HeaderButton = styled.button `
  font-weight: ${(props) => props.fontWeight};
  margin: ${(props) => props.margin};
  display: flex;
  align-items: center;
  max-width: 160px;
`;
export const IconWrapper = styled.div `
  margin-right: 10px;
`;
export const ContentContainer = styled.div `
  display: flex;
  flex-direction: column;
  padding: 0 15px 20px 15px;
  justify-content: space-between;
`;
export const StyledButton = styled.button `
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${(props) => props.fontWeight};
  padding: 0;
  text-align: left;
`;
export const InnerContainer = styled.div `
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${(props) => props.theme.input.borderColors.default};
  padding: 10px 0 10px 0;
`;
export const PriceLabel = styled.p `
  font-weight: bold;
  margin: 0;
`;
export const OptionLabel = styled.p `
  margin: 0;
  padding-top: 5px;
`;
export const BottomButton = styled(Button) `
  align-self: center;
  width: 210px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  /* Only for Safari */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      padding-bottom: 80px;
    }
  }

  /* Only for Firefox */
  @-moz-document url-prefix() {
    padding-bottom: 80px;
  }
`;
export const RemoveFilterButton = styled.button ``;
export const FilterButtonWrapper = styled.div `
  display: flex;
`;
export const FiltersHeaderWrapper = styled.div `
  margin-bottom: 10px;
  display: flex;
`;
export const FiltersHeader = styled.h3 `
  font-size: 16px;
  text-transform: capitalize;
`;
export const FiltersClearButton = styled.button `
  margin-left: 5px;
  font-weight: 600;
`;
export const SpinnerWrapper = styled.div `
  padding-top: 40px;
`;
