import { __awaiter } from "tslib";
import React, { useEffect, useState } from 'react';
import { CategoriesContainer, CategoriesListContainer, Title, Subtitle, CategoryLabel, SubCategoriesContainer, } from '@@Pages/CategoryPage/CategoriesList/styled';
import { useLang } from '@@Hooks/useLang';
import { useCategoryTree } from '@@Hooks/useCategoryTree';
import LSF from '@@Storefront';
export const CategoriesList = ({ isTitle = true, categoryId, }) => {
    const categoriesTree = useCategoryTree(categoryId);
    const { translate } = useLang();
    const [categories, setCategories] = useState([]);
    const [currentSubCategory, setCurrentSubCategory] = useState();
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () { return setCategories(categoriesTree); }))();
    }, [categoriesTree]);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            const current = yield LSF.CategoryNextService.getCurrentCategory({
                path: location.pathname,
            });
            setCurrentSubCategory(current);
        }))();
    }, [categories]);
    if (!categories || !(categories instanceof Array) || !categories.length)
        return null;
    return (React.createElement(CategoriesContainer, null,
        React.createElement(Title, { isVisible: isTitle }, translate('products.categories')),
        React.createElement(CategoriesListContainer, null, categories.map((element, index) => (React.createElement("div", { key: index },
            React.createElement(Subtitle, null, element === null || element === void 0 ? void 0 : element.name),
            element.children && (React.createElement(SubCategoriesContainer, null,
                (element === null || element === void 0 ? void 0 : element.is_brand_root) === '1' && (React.createElement(CategoryLabel, { href: element.url_path }, location.pathname === `/${element.url_path}` ? (React.createElement("b", null,
                    " ",
                    translate('categories.all'),
                    " ")) : (translate('categories.all')))),
                element.children.map((subCategory, index) => (React.createElement(CategoryLabel, { key: index, href: subCategory.url_path }, location.pathname === `/${subCategory.url_path}` ? (React.createElement("b", null,
                    " ", subCategory === null || subCategory === void 0 ? void 0 :
                    subCategory.name,
                    " ")) : (subCategory === null || subCategory === void 0 ? void 0 : subCategory.name))))))))))));
};
