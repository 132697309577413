import styled from 'styled-components';
export const CounterContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  margin-right: 15px;
`;
export const Count = styled.h1 `
  font-size: 32px;
  margin-bottom: 10px;
`;
export const Caption = styled.p `
  line-height: 22px;
  text-align: center;
`;
