import styled from 'styled-components';
export const BreadcrumbsContainer = styled.div `
  padding-top: 2rem;
`;
export const MainInnerContainer = styled.section `
  display: flex;
  flex-direction: column;
  width: 1440px;
  padding-bottom: 45px;
  @media (max-width: 1439px) {
    width: 100%;
  }
  ${(props) => props.theme.mixins.mobile('padding-bottom: 35px;')};
`;
export const TabsWrapper = styled.div `
  width: auto;
  margin: 0 35px;
  @media (max-width: 700px) {
    margin: 0 15px;
  }
  @media (max-width: 450px) {
    margin: 10px;
  }
`;
export const FormWrapper = styled.div `
  width: 670px;
  align-self: center;
  margin-bottom: 15px;
  margin-top: 45px;
  ${(props) => props.theme.mixins.mobile(`
    width: 100%; 
    padding: 0 60px;
  `)};
  @media (max-width: 700px) {
    padding: 0 15px;
  }
`;
export const SpinnerWrapper = styled.div `
  margin-top: 40vh;
`;
