import styled from 'styled-components';
export const TextareaWrapper = styled.div `
  margin-top: ${(props) => (props.placeholder ? '12' : '0')}px;
  position: relative;
  height: inherit;
`;
export const TextareaField = styled.textarea `
  box-sizing: border-box;
  width: 100%;
  height: inherit;
  border: none;
  outline: none;
  padding: 11px 10px;
  line-height: 20px;
  border: 1px solid
    ${(props) => props.error && !props.disabled
    ? props.theme.colors.error
    : props.theme.colors.borderLight};
  min-height: 80px;
  ${(props) => props.disableResize && 'resize: none;'}
  &[disabled] {
    cursor: not-allowed;
  }
  ${(props) => !props.error &&
    `
    &:focus {
      border: 1px solid ${props.theme.input.borderColors.focus};
    }
    &:not([disabled]):hover {
      border-color: ${props.theme.input.borderColors.hover};
    }
  `}

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px
      ${(props) => props.theme.input.normal.backgroundColor} inset;
  }

  &[disabled]:-webkit-autofill,
  &[disabled]:-webkit-autofill:hover,
  &[disabled]:-webkit-autofill:focus,
  &[disabled]:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px
      ${(props) => props.theme.input.disabled.backgroundColor} inset;
  }

  &[type='number']::-webkit-outer-spin-button,
  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  color: ${(props) => props.theme.input.normal.textColor};
  -webkit-text-fill-color: ${(props) => props.theme.input.normal.textColor};
  &[disabled] {
    color: ${(props) => props.theme.input.disabled.textColor};
    -webkit-text-fill-color: ${(props) => props.theme.input.disabled.textColor};
  }

  ::-webkit-input-placeholder {
    opacity: 1;
  }
  ::-moz-placeholder {
    opacity: 1;
  }
  :-ms-input-placeholder {
    opacity: 1;
  }

  &::placeholder {
    opacity: 1;
    color: ${(props) => props.theme.input.normal.placeholderTextColor};
    -webkit-text-fill-color: ${(props) => props.theme.input.normal.placeholderTextColor};
    &[disabled] {
      color: ${(props) => props.theme.input.disabled.placeholderTextColor};
      -webkit-text-fill-color: ${(props) => props.theme.input.disabled.placeholderTextColor};
    }
  }
  background: ${(props) => props.theme.input.normal.backgroundColor};
  &[disabled] {
    background: ${(props) => props.theme.input.disabled.backgroundColor};
  }

  filter: none; /* Disable autofill on firefox */
`;
export const TextareaInnerWrapper = styled.div `
  position: relative;
  height: inherit;
`;
export const TextareaPlaceholder = styled.label `
  color: ${(props) => props.disabled
    ? props.theme.input.disabled.placeholderTextColor
    : props.theme.input.normal.textColor};
  background-color: ${(props) => props.theme.input[props.disabled ? 'disabled' : 'normal'].backgroundColor};
  display: inline-block;
  position: absolute;
  top: 0;
  left: 8px;
  padding: 0 6px;
  font-size: 13px;
  font-weight: bold;
  pointer-events: none;
  transform: translateY(-50%);
  transition: all 100ms linear;
`;
export const TextareaErrorTooltipContainer = styled.div `
  position: absolute;
  right: 10px;
  top: 20px;
  transform: translateY(-50%);
`;
