import styled from 'styled-components';
export const PostContainer = styled.div `
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  border-bottom: 1px solid ${(props) => props.theme.input.borderColors.default};
`;
export const Title = styled.p `
  font-size: 1.14rem;
  line-height: 1.85rem;
  margin: 0;
  font-weight: 700;
`;
export const Description = styled.p `
  margin: 20px 0;
`;
