import 'swiper/swiper.scss';
import React, { useEffect, useRef, useState } from 'react';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react';
import { BannerSliderNavigator } from '@@Components/BannersSlider/BannerSliderNavigator';
import { MotorcycleTypeBanner } from '@@Components/MotorcycleTypeBanner';
import GTMEvents from '@@Common/gtm';
import { BannersSliderWrapper } from './styled';
SwiperCore.use([Navigation, Pagination, Autoplay]);
export const BannerSliderTwoSide = (props) => {
    var _a;
    const [loop] = useState(props.loop || false);
    const [changeSlide, setChangeSlide] = useState(true);
    const [autoplay] = useState(props.autoplay || false);
    const [swiperInstance, setSwiperInstance] = useState(null);
    const ref = useRef(null);
    const maxSlides = props.items.length - 1;
    const setArrowsVisibility = (e = null) => {
        if (ref === null || ref === void 0 ? void 0 : ref.current) {
            const nextSlide = ref.current.querySelector('.next_slide');
            const prevSlide = ref.current.querySelector('.prev_slide');
            if (!nextSlide || !prevSlide)
                return;
            nextSlide.style.display =
                (e === null || e === void 0 ? void 0 : e.activeIndex) === maxSlides - 1 ? 'none' : 'block';
            prevSlide.style.display =
                (e === null || e === void 0 ? void 0 : e.activeIndex) === 0 || !(e === null || e === void 0 ? void 0 : e.activeIndex) ? 'none' : 'block';
        }
    };
    useEffect(() => !loop && setArrowsVisibility(), []);
    if (!((_a = props.items) === null || _a === void 0 ? void 0 : _a.length))
        return null;
    const sendGTMClickEvent = (label) => {
        if (label)
            GTMEvents.push({
                event: 'homepageSliderSlideClick',
                bannerTitle: label,
            });
    };
    useEffect(() => {
        const slides = document
            .querySelector('.swiper-container')
            .querySelectorAll('.swiper-slide');
        Array.from(slides).map((el) => {
            if (!el.classList.contains('swiper-slide-active')) {
                el.querySelector('img').setAttribute('loading', 'lazy');
            }
        });
    }, []);
    return (React.createElement(BannersSliderWrapper, { ref: ref },
        React.createElement(SwiperComponent, Object.assign({}, props.options, { spaceBetween: 4, loop: true, slidesPerView: props.slidesPerView || 'auto', slideNextClass: "gallery-next", slidePrevClass: "gallery-prev", onSwiper: setSwiperInstance, allowSlidePrev: true, allowTouchMove: true, autoplay: autoplay && {
                delay: 5000,
                disableOnInteraction: false,
            }, className: "mySwiper", modules: [Pagination, Navigation, Autoplay], onSlideChange: (e) => !loop && setArrowsVisibility(e) }), props.items.map((item, i) => {
            const isBig = i % 2 === 0;
            return (React.createElement(SwiperSlide, { style: { width: isBig ? '59.7%' : '40%' }, key: i },
                React.createElement(MotorcycleTypeBanner, { width: isBig ? 860 : 576, height: 450, media: item, onLinkClick: () => sendGTMClickEvent(item.title) })));
        })),
        React.createElement(BannerSliderNavigator, { count: props.items.length, swiperInstance: swiperInstance })));
};
