import { __awaiter } from "tslib";
import React, { useState, useEffect, useRef } from 'react';
import { CheckoutContainer, CheckoutInnerContainer, LeftColumn, RightColumn, TileWrapper, HeadingTitle, Title, TileImage, DetailsSection, TextRow, Text, DetailsFooter, BigPrice, SubmitButton, ColumnDivider, NoResultsWrapper, } from '@@Pages/CheckoutPage/styled';
import LSF, { PriceUtils } from '@@Storefront';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Spinner } from '@@Components/Spinner';
import { SearchQuery, ThumbnailUtils, } from '@grupakmk/libstorefront';
import { translate } from '@@Helpers/translateGlobal';
import { mageConfigSelector } from '@@Redux/selectors/config.selectors';
import { useRouter } from '@@Hooks/useRouter';
import { NoSearchResults } from '@liberty/components/src/NoSearchResults';
import GTMEvents from '@@Common/gtm';
import { addDaysQtyToBundleCartItem } from '@@App/src/helpers/addDaysQtyToBundleCartItem';
import { waitForTotalsRentalItemQty } from '@@App/src/helpers/waitForTotalsRentalItemQty';
import { CheckoutPageForm, } from './CheckoutPageForm';
export const LoadingContainer = styled.div `
  padding: 250px 0;
`;
const CheckoutPage = ({}) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    const [isOrderInProgress, setIsOrderInProgress] = useState(false);
    const config = useSelector(mageConfigSelector);
    const [cartInfo, setCartInfo] = useState();
    const [cartItems, setCartItems] = useState();
    const [cartItem, setCartItem] = useState(null);
    const [product, setProduct] = useState(null);
    const [productImageUrl, setProductImageUrl] = useState(null);
    const [days, setDays] = useState(0);
    const [accessory, setAccessory] = useState([]);
    const checkoutUserDataFormRef = useRef();
    const [formIsValid, setFormIsValid] = useState(false);
    const [pending, setPending] = useState(true);
    const { goTo } = useRouter();
    const lang = {
        data: translate('checkout.reservation_data'),
        terms: translate('checkout.terms'),
        details: translate('checkout.reservation_details'),
        pickupDate: translate('checkout.pickup_date'),
        returnDate: translate('checkout.return_date'),
        days: translate('checkout.number_of_days'),
        pickupPlace: translate('checkout.vehicle_pickup_place'),
        returnPlace: translate('checkout.vehicle_return_place'),
        day: translate('common.day_2'),
        inPrice: translate('checkout.in_price'),
        summary: translate('checkout.summary'),
        orderValue: translate('checkout.order_value'),
        payment: translate('checkout.payment'),
        cardPayment: translate('checkout.card_payment_in_showroom'),
        deposit: translate('checkout.refundable_deposit'),
        totalAmount: translate('checkout.total_amount'),
        withTax: translate('checkout.with_tax'),
        net: translate('checkout.net'),
        submit: translate('actions.submit_reservation'),
    };
    const getPrice = (price) => {
        if (!price) {
            return '';
        }
        const formattedPrice = PriceUtils.getFormattedPrice(price);
        return formattedPrice.price;
    };
    const getProductImage = (product) => {
        const [image] = product.media_gallery;
        if (image) {
            return ThumbnailUtils.getImageSrc({
                image: image === null || image === void 0 ? void 0 : image.image,
                width: 500,
                height: 426,
                entity: '',
            });
        }
    };
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            yield waitForTotalsRentalItemQty();
            GTMEvents.beginCheckout({
                itemTransformer: (product) => __awaiter(void 0, void 0, void 0, function* () {
                    var _a, _b;
                    const rentalDaysQty = Number((_b = (_a = product.totals) === null || _a === void 0 ? void 0 : _a.extension_attributes) === null || _b === void 0 ? void 0 : _b.rental_item_qty);
                    if (!Number.isNaN(rentalDaysQty)) {
                        return yield addDaysQtyToBundleCartItem(product, rentalDaysQty);
                    }
                    else {
                        return product;
                    }
                }),
            });
        }))();
    }, []);
    useEffect(() => {
        if (product === null || product === void 0 ? void 0 : product.media_gallery) {
            setProductImageUrl(getProductImage(product));
        }
    }, [product]);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b;
            try {
                const cartInfo = yield LSF.CartService.getCartInfo(true);
                setCartInfo(cartInfo);
                const [cartItem] = cartInfo.items;
                const days = (_b = (_a = cartItem) === null || _a === void 0 ? void 0 : _a.extension_attributes) === null || _b === void 0 ? void 0 : _b.rental_item_qty;
                if (days && !Number.isNaN(Number(days))) {
                    setDays(days);
                }
                const bundleId = cartItem.extension_attributes.item_product_id;
                const query = new SearchQuery();
                query.applyFilter({ key: 'id', value: bundleId });
                const bundle = yield LSF.ProductService.getProduct({
                    query,
                    setCurrentProduct: false,
                });
                const bundleProductOptions = bundle.extension_attributes
                    .bundle_product_options;
                const selectedOptions = cartItem.product_option.extension_attributes.bundle_options.map((option) => {
                    const bundleSelections = bundleProductOptions.find((item) => item.option_id === option.option_id);
                    return {
                        isMotor: ['motor', 'bike'].includes(bundleSelections.title.toLowerCase()),
                        option_id: option.option_id,
                        title: bundleSelections.title,
                        selections: option.option_selections.map((itemId) => bundleSelections.product_links.find((link) => {
                            return String(link.id) === String(itemId);
                        })),
                    };
                });
                const accessoryItems = selectedOptions
                    .filter((option) => !option.isMotor)
                    .reduce((acc, curr) => {
                    return [
                        ...acc,
                        ...curr.selections.map((selection) => (Object.assign(Object.assign({}, selection), { perDay: curr.title.toLowerCase() === 'additional_items' }))),
                    ];
                }, []);
                setAccessory([
                    ...accessoryItems,
                    { sku: 'ubezpieczenie', gratis: true, perDay: false },
                ]);
                const productSku = selectedOptions.find((option) => option.isMotor === true).selections[0].sku;
                const productQuery = new SearchQuery();
                productQuery.applyFilter({ key: 'sku_c.keyword', value: productSku });
                const product = yield LSF.ProductService.getProduct({
                    query: productQuery,
                    setCurrentProduct: false,
                });
                setProduct(product);
                setCartItem(cartItem);
                setPending(false);
            }
            catch (error) {
                console.log('cart loading error:', error);
                setPending(false);
            }
        }))();
    }, []);
    const handleFormChange = (event) => {
        setFormIsValid(event.isValid);
    };
    const getCartItems = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!(cartItems === null || cartItems === void 0 ? void 0 : cartItems.length)) {
            const cartitems = yield LSF.CartService.getItems();
            setCartItems(cartitems);
        }
    });
    useEffect(() => {
        getCartItems();
    }, [, cartInfo]);
    const getCartToken = () => {
        try {
            return JSON.parse(window.localStorage.getItem('shop/cart/current-cart-token'));
        }
        catch (error) {
            console.log('Error getting cart token:', error);
        }
    };
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        var _q;
        if (!formIsValid) {
            (checkoutUserDataFormRef === null || checkoutUserDataFormRef === void 0 ? void 0 : checkoutUserDataFormRef.current).triggerShowValidation();
            return;
        }
        const formData = checkoutUserDataFormRef.current.getFormContent();
        const { firstName, lastName, phoneNumber, emailAddress, comment } = formData;
        const addressInformation = {
            shippingAddress: {
                country_id: config.shipping.country_id,
                street: [config.shipping.street_line1, config.shipping.street_line2],
                telephone: phoneNumber,
                postcode: config.shipping.postcode,
                city: config.shipping.city,
                firstname: firstName,
                lastname: lastName,
            },
            billingAddress: {
                company: '',
                country_id: config.shipping.country_id,
                street: [config.shipping.street_line1, config.shipping.street_line2],
                telephone: phoneNumber,
                postcode: config.shipping.postcode,
                city: config.shipping.city,
                firstname: firstName,
                lastname: lastName,
                email: emailAddress,
            },
            shipping_method_code: 'freeshipping',
            shipping_carrier_code: 'freeshipping',
        };
        const order = {
            cart_id: getCartToken(),
            products: [
                {
                    id: cartItem.id,
                    item_id: cartItem.item_id,
                    qty: 1,
                },
            ],
            country: config.shipping.country_id,
            addressInformation: Object.assign(Object.assign({}, addressInformation), { payment_method_code: 'checkmo', payment_method_additional: null, shippingExtraFields: comment
                    ? {
                        customer_note: comment,
                    }
                    : {} }),
        };
        try {
            setIsOrderInProgress(true);
            const orderResult = yield LSF.OrderService.placeOrder(order);
            if ((_q = orderResult === null || orderResult === void 0 ? void 0 : orderResult.result) === null || _q === void 0 ? void 0 : _q.orderNumber) {
                yield GTMEvents.purchase(orderResult.result.orderNumber);
                window.localStorage.setItem('orderId', orderResult.result.orderNumber);
                goTo('/checkout/success');
            }
            console.log('order result:', orderResult);
            setIsOrderInProgress(false);
        }
        catch (error) {
            console.log('error creating order:', error);
            setIsOrderInProgress(false);
        }
    });
    if (pending)
        return (React.createElement(LoadingContainer, null,
            React.createElement(Spinner, { size: 40, speed: "0.5s" })));
    if (!(cartItems === null || cartItems === void 0 ? void 0 : cartItems.length) || !product || !cartItem)
        return (React.createElement(NoResultsWrapper, null,
            React.createElement(NoSearchResults, { title: translate('placeholders.no_products_title'), description: translate('placeholders.no_products_description'), image: './assets/image/placeholders/no_search_results.svg', isEmailPhone: false, buttonText: translate('actions.back_to_homepage'), buttonLink: "/" })));
    return (React.createElement(CheckoutContainer, null,
        React.createElement(CheckoutInnerContainer, null,
            React.createElement(LeftColumn, null,
                React.createElement(TileWrapper, null,
                    React.createElement(HeadingTitle, null, product.name),
                    productImageUrl && React.createElement(TileImage, { src: productImageUrl })),
                React.createElement(CheckoutPageForm, { onChange: handleFormChange, ref: checkoutUserDataFormRef })),
            React.createElement(ColumnDivider, null),
            React.createElement(RightColumn, null,
                React.createElement(Title, { paddingBottom: '25px', mobilePaddingBottom: '20px' }, lang.details),
                React.createElement(DetailsSection, { paddingBottom: '10px' },
                    React.createElement(TextRow, null,
                        React.createElement(Text, { fontWeight: "bold" }, lang.pickupDate),
                        React.createElement(Text, { paddingLeft: "5px" }, (_b = (_a = cartItem) === null || _a === void 0 ? void 0 : _a.extension_attributes) === null || _b === void 0 ? void 0 : _b.rental_date_from)),
                    React.createElement(TextRow, null,
                        React.createElement(Text, { fontWeight: "bold" }, lang.returnDate),
                        React.createElement(Text, { paddingLeft: "5px" }, (_d = (_c = cartItem) === null || _c === void 0 ? void 0 : _c.extension_attributes) === null || _d === void 0 ? void 0 : _d.rental_date_to)),
                    days && typeof days === 'number' && days > 0 ? (React.createElement(TextRow, null,
                        React.createElement(Text, { fontWeight: "bold" }, lang.days),
                        React.createElement(Text, { paddingLeft: "5px" }, days))) : (''),
                    React.createElement(TextRow, null,
                        React.createElement(Text, { fontWeight: "bold" }, lang.pickupPlace),
                        React.createElement(Text, { paddingLeft: "5px" }, (_f = (_e = cartItem) === null || _e === void 0 ? void 0 : _e.extension_attributes) === null || _f === void 0 ? void 0 : _f.rental_place_from)),
                    React.createElement(TextRow, null,
                        React.createElement(Text, { fontWeight: "bold" }, lang.returnPlace),
                        React.createElement(Text, { paddingLeft: "5px" }, (_h = (_g = cartItem) === null || _g === void 0 ? void 0 : _g.extension_attributes) === null || _h === void 0 ? void 0 : _h.rental_place_to))),
                React.createElement(DetailsSection, { paddingBottom: '20px', mobilePaddingBottom: '15px' }, accessory.map((element, index) => {
                    var _a;
                    return (React.createElement(TextRow, { key: index, justifyContent: "space-between" },
                        React.createElement(TextRow, null,
                            React.createElement(Text, { fontWeight: "bold", paddingRight: "5px" },
                                ((_a = element.extension_attributes) === null || _a === void 0 ? void 0 : _a.item_product_name) ||
                                    element.sku,
                                React.createElement("span", { style: { fontWeight: 'normal', whiteSpace: 'nowrap' } }, element.perDay &&
                                    ` (${getPrice(element.price)} / ${lang.day})`))),
                        React.createElement(Text, { fontWeight: "600", noWrap: true }, element.gratis
                            ? 'gratis'
                            : getPrice(Number(element.price) * (element.perDay ? days : 1)))));
                })),
                React.createElement(Title, { paddingBottom: '25px', mobilePaddingBottom: '20px' }, lang.summary),
                React.createElement(TextRow, { justifyContent: "space-between" },
                    React.createElement(Text, { fontWeight: "bold" }, lang.orderValue),
                    React.createElement(Text, { fontWeight: "600" }, getPrice((_k = (_j = (cartItems && cartItems[0])) === null || _j === void 0 ? void 0 : _j.totals) === null || _k === void 0 ? void 0 : _k.base_price))),
                React.createElement(TextRow, { justifyContent: "space-between" },
                    React.createElement(Text, { fontWeight: "bold", marginBottom: '0' }, lang.payment),
                    React.createElement(Text, { fontWeight: "600", marginBottom: '0' }, lang.cardPayment)),
                React.createElement(TextRow, { justifyContent: "flex-end" },
                    React.createElement(Text, { fontWeight: "600", textAlign: "right" }, `(${lang.deposit})`)),
                React.createElement(DetailsFooter, null,
                    React.createElement(TextRow, { justifyContent: "space-between", alignItems: "flex-end" },
                        React.createElement(TextRow, null,
                            React.createElement(Text, { marginBottom: "0", fontWeight: "bold" }, lang.totalAmount),
                            React.createElement(Text, { marginBottom: "0", paddingLeft: "5px" }, lang.withTax)),
                        React.createElement(BigPrice, null, getPrice((_m = (_l = (cartItems && cartItems[0])) === null || _l === void 0 ? void 0 : _l.totals) === null || _m === void 0 ? void 0 : _m.base_price_incl_tax))),
                    React.createElement(TextRow, { justifyContent: "space-between" },
                        React.createElement(Text, { marginBottom: "0" },
                            lang.totalAmount,
                            " ",
                            lang.net),
                        React.createElement(Text, { fontWeight: "600" }, getPrice((_p = (_o = (cartItems && cartItems[0])) === null || _o === void 0 ? void 0 : _o.totals) === null || _p === void 0 ? void 0 : _p.base_price)))),
                React.createElement(SubmitButton, { appearance: "dark", onClick: () => handleSubmit(), loading: isOrderInProgress }, lang.submit)))));
};
export default CheckoutPage;
