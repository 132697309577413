import styled from 'styled-components';
export const NewsletterSubscriptionConfirmationPageWrapper = styled.div `
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  white-space: pre-wrap;
  text-align: center;
  p {
    margin: 35px 0;
  }
  a {
    width: 212px;
  }
  ${(props) => props.error &&
    `
        h1 {
            color: ${props.theme.colors.error};
        }
    `}
`;
