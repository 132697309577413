import styled from 'styled-components';
export const ProductPageContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ProductPageInnerContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1135px;
  padding-top: 20px;
  @media screen and (max-width: 1255px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media screen and (max-width: 1135px) {
    width: 900px;
    padding-left: 0;
    padding-right: 0;
  }
  @media screen and (max-width: 960px) {
    width: 822px;
  }
  @media screen and (max-width: 890px) {
    width: 762px;
  }
  @media screen and (max-width: 810px) {
    width: 100%;
    padding-top: 10px;
  }
`;
export const TabsWrapper = styled.div `
  width: 100%;
  @media screen and (max-width: 810px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;
export const DescriptionTabContainer = styled.div `
  display: flex;
  width: 100%;
  padding-bottom: 45px;
`;
export const LeftColumn = styled.div `
  display: flex;
  flex-direction: column;
  width: 670px;
`;
