import React from 'react';
import { ThumbnailUtils } from '@@Storefront';
export const RetinaThumborImage = (props) => {
    const params = {
        image: props.path,
        entity: props.entity,
        fit: '',
        filters: ['smart'],
    };
    const thumborUrl = ThumbnailUtils.getImageSrc(Object.assign(Object.assign({}, params), { width: props.size.width, height: props.size.height }));
    const thumborUrl2x = ThumbnailUtils.getImageSrc(Object.assign(Object.assign({}, params), { width: props.size.width * 2, height: props.size.height * 2 }));
    return (React.createElement("img", { srcSet: `${thumborUrl} 1x, ${thumborUrl2x} 2x`, src: thumborUrl, alt: props.alt }));
};
