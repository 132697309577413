import styled from 'styled-components';
export const BannersSliderWrapper = styled.div `
  position: relative;
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    ${(props) => (props.isTabletVersion || props.isMobileVersion) &&
    `
      margin-right: 25px;
    `}
    @media (max-width: 768px) {
      margin-right: 25px;
    }
  }
  .swiper-wrapper {
    ${(props) => !(props.isTabletVersion || props.isMobileVersion) &&
    `
        left: -30px;
    `}
  }
`;
export const NavigationButton = styled.button `
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 1rem 0.5rem;
  border: 6px;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 3px 3px 6px #00000010;
  z-index: 1;
  svg {
    min-height: 22px;
  }
`;
export const NavigationButtonLeft = styled(NavigationButton) `
  left: 5px;
  width: 26px;
  height: 40px;
  padding: 9px 8px;
  svg {
    min-height: 0;
    width: 10px !important;
    height: 22px !important;
  }
`;
export const NavigationButtonRight = styled(NavigationButton) `
  right: 5px;
  width: 26px;
  height: 40px;
  padding: 9px 8px;
  svg {
    min-height: 0;
    width: 10px !important;
    height: 22px !important;
  }
`;
export const MobileNavigatorContainer = styled.div `
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;
export const MobileNavigatorPaginatorWrapper = styled.div `
  padding: 10px 5px;
  cursor: pointer;
`;
export const MobileNavigatorPaginator = styled.div `
  --size: ${(props) => (props.active ? '8px' : '6px')};
  margin: ${(props) => (props.active ? '0' : '1px')};
  height: var(--size);
  width: var(--size);
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.white};
  opacity: ${(props) => (props.active ? 1 : 0.81)};
  cursor: pointer;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
`;
