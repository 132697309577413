import React from 'react';
import styled from 'styled-components';
import { AnimatedBackground } from '@@Components/Placeholders/styled';
const DynamicFormPlaceholderWrapper = styled.div `
  display: flex;
  flex-flow: column;
  max-width: 670px;
  margin: auto;
`;
const DynamicFormPlaceholderRow = styled(AnimatedBackground) `
  width: 100%;
  height: 44px;
  margin-bottom: 15px;
`;
export const DynamicFormPlaceholder = ({ rows = 8 }) => {
    return (React.createElement(DynamicFormPlaceholderWrapper, null,
        React.createElement(DynamicFormPlaceholderRow, { style: { width: '300px', marginBottom: '40px' } }),
        Array(rows)
            .fill(0)
            .map((_, i) => (React.createElement(DynamicFormPlaceholderRow, { key: i })))));
};
