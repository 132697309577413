import styled from 'styled-components';
export const CategoryDescriptionContainer = styled.div `
  display: flex;
  flex-direction: column;
`;
export const HeaderText = styled.h4 `
  margin: 0 5px 0 0;
  font-size: 20px;
`;
export const Description = styled.p `
  font-size: 13px;
  line-height: 18px;
  margin: 20px 0;
`;
