import React, { useRef, useState } from 'react';
import Icon from '@@Components/Icon/Icon';
import { useClickOutside } from '@@Hooks/useClickOutside';
import { DropdownContent, DropdownOptionWrapper, DropdownPlaceholder, DropdownPlaceholderWrapper, DropdownTriggerContainer, DropdownWrapper, } from '@@Pages/CategoryPage/Dropdown/styled';
export const Dropdown = (props) => {
    const [opened, setOpened] = useState(false);
    const contentRef = useRef();
    useClickOutside(contentRef, () => {
        setOpened(false);
    });
    const handleOptionClick = (option) => {
        if (props.onChange)
            props.onChange(option);
        setOpened(false);
    };
    const getValueLabel = () => {
        var _a, _b;
        const label = props.value && ((_b = (_a = props.options) === null || _a === void 0 ? void 0 : _a.find(({ value }) => value === props.value)) === null || _b === void 0 ? void 0 : _b.label);
        return label || props.placeholder;
    };
    return (React.createElement(DropdownWrapper, null,
        React.createElement(DropdownTriggerContainer, null,
            React.createElement(DropdownPlaceholderWrapper, { onClick: () => setOpened(!opened) },
                React.createElement(DropdownPlaceholder, null, getValueLabel()),
                React.createElement(Icon, { icon: "expand", rotate: opened ? '180' : '0' }))),
        opened && props.options && (React.createElement(DropdownContent, { ref: contentRef }, props.options.map((option) => (React.createElement(DropdownOptionWrapper, { key: option.value, onClick: () => handleOptionClick(option) }, option.label)))))));
};
