import styled from 'styled-components';
import { Button } from '@@Components/Buttons/Button';
export const ProductLongTileContainer = styled.div `
  display: flex;
  height: 243px;
  border: 1px solid ${(props) => props.theme.input.borderColors.default};
  padding: 15px;
  margin-bottom: 10px;
  @media screen and (max-width: 1240px) {
    padding: 10px;
  }
`;
export const ProductImage = styled.div `
  width: 250px;
  height: 213px;
  background-image: url('${(props) => props.url}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  @media screen and (max-width: 1050px) {
    margin-right: 50px;
  }
  @media screen and (max-width: 970px) {
    margin-right: 30px;
  }
  ${(props) => props.theme.mixins.mobile('margin-right: 0;')}
  @media screen and (max-width: 800px) {
    margin-right: 50px;
  }
  @media screen and (max-width: 620px) {
    margin-right: 20px;
  }
  @media screen and (max-width: 580px) {
    margin-right: 10px;
  }
`;
export const TextContainer = styled.div `
  display: flex;
  flex-direction: column;
  margin: 0 25px;
  width: 440px;
  @media screen and (max-width: 1320px) {
    width: 380px;
  }
  @media screen and (max-width: 1260px) {
    width: 340px;
  }
  @media screen and (max-width: 1240px) {
    width: 320px;
  }
  @media screen and (max-width: 1160px) {
    width: 280px;
  }
  @media screen and (max-width: 1120px) {
    margin: 0 15px;
  }
  @media screen and (max-width: 1100px) {
    margin: 0 10px;
  }
  @media screen and (max-width: 1050px) {
    display: none;
  }
  ${(props) => props.theme.mixins.mobile('display: flex;')}
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
export const Title = styled.h3 `
  white-space: nowrap;
  overflow: hidden;
  max-width: 95%;
  text-overflow: ellipsis;
  padding-bottom: 25px;
  @media screen and (max-width: 1240px) {
    font-size: 18px;
    padding-bottom: 10px;
  }
  @media screen and (max-width: 1100px) {
    font-size: 16px;
  }
`;
export const AlternativeTitle = styled.h3 `
  display: none;
  white-space: nowrap;
  overflow: hidden;
  max-width: 95%;
  text-overflow: ellipsis;
  padding-bottom: 25px;
  @media screen and (max-width: 1050px) {
    display: block;
  }
  @media screen and (max-width: 1240px) {
    font-size: 18px;
    padding-bottom: 10px;
  }
  @media screen and (max-width: 1100px) {
    font-size: 16px;
  }
  @media screen and (max-width: 1050px) {
    padding-bottom: 0;
  }
  ${(props) => props.theme.mixins.mobile('display: none;')}
  @media screen and (max-width: 800px) {
    display: block;
    font-size: 14px;
  }
`;
export const DescriptionContainer = styled.div ``;
export const DescriptionText = styled.div `
  line-height: 23px;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 175px;
  @media screen and (max-width: 1240px) {
    -webkit-line-clamp: 7;
  }
`;
export const InformationContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 250px;
  height: 100%;
  @media screen and (max-width: 1240px) {
    width: 220px;
  }
  @media screen and (max-width: 1100px) {
    width: 180px;
  }
  @media screen and (max-width: 1050px) {
    width: 250px;
  }
  @media screen and (max-width: 860px) {
    width: 200px;
  }
  @media screen and (max-width: 820px) {
    width: 180px;
  }
  @media screen and (max-width: 800px) {
    width: 300px;
  }
  @media screen and (max-width: 670px) {
    width: 250px;
  }
  @media screen and (max-width: 560px) {
    width: 180px;
  }
`;
export const InformationInnerContainer = styled.div ``;
export const PriceLine = styled.div `
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  height: 30px;
  margin-bottom: 5px;
  @media screen and (max-width: 1100px) {
    height: 28px;
  }
`;
export const PriceLabel = styled.h1 `
  font-size: 30px;
  padding: 0 10px 0 5px;
  color: #d9000d;
  margin: 0;
  @media screen and (max-width: 1240px) {
    font-size: 24px;
  }
  @media screen and (max-width: 1100px) {
    font-size: 18px;
  }
  ${(props) => props.theme.mixins.mobile('font-size: 16px;')}
`;
export const Text = styled.p `
  font-size: 16px;
  padding-top: 10px;
  margin: 0;
  padding-bottom: 2px;
  @media screen and (max-width: 1240px) {
    font-size: 14px;
    padding-bottom: 4px;
  }
  @media screen and (max-width: 1100px) {
    padding-bottom: 8px;
  }
`;
export const TextLinesWrapper = styled.div ``;
export const TextLine = styled.div `
  padding: 10px 0;
  border-bottom: 1px solid ${(props) => props.theme.input.borderColors.default};
  &:last-child {
    border: none;
  }
`;
export const TextLineInner = styled.p `
  margin: 0;
  overflow: hidden;
  max-width: 95%;
  text-overflow: ellipsis;
`;
export const StyledButton = styled(Button) `
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  @media screen and (max-width: 1240px) {
    margin-top: 22px;
  }
  @media screen and (max-width: 1100px) {
    margin-top: 32px;
    height: 34px;
  }
  @media screen and (max-width: 1050px) {
    margin-top: 0;
  }
  ${(props) => props.theme.mixins.mobile('margin-top: 32px;')}
  @media screen and (max-width: 800px) {
    margin-top: 4px;
  }
`;
