import styled from 'styled-components';
export const BannersSliderWrapper = styled.div `
  position: relative;
  aspect-ratio: 1440 / 450;
  .swiper-container {
    height: 100%;
  }
`;
const NavigationButton = styled.div `
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 1rem 0.5rem;
  border: 6px;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 3px 3px 6px #00000010;
  z-index: 1;
  svg {
    min-height: 22px;
  }
`;
export const NavigationButtonLeft = styled(NavigationButton) `
  left: 1rem;
`;
export const NavigationButtonRight = styled(NavigationButton) `
  right: 1rem;
`;
