import styled from 'styled-components';
export const DownloadButtonsWrapper = styled.div `
  width: 100%;
`;
export const DownloadButton = styled.button `
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
`;
export const ButtonContent = styled.div `
  font-size: 14px;
  font-weight: 600;
  padding-left: 15px;
`;
