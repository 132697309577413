import styled from 'styled-components';
export const CustomerServiceContainer = styled.div `
  width: 300px;
  display: flex;
  flex-direction: column;
  margin-left: 140px;
  @media screen and (max-width: 1100px) {
    margin-left: 110px;
  }
  @media screen and (max-width: 960px) {
    margin-left: 80px;
  }
  @media screen and (max-width: 810px) {
    display: none;
  }
`;
export const Title = styled.h4 `
  padding-bottom: 35px;
  line-height: 24px;
`;
export const Mail = styled.a `
  font-size: 15px;
  line-height: 21px;
  font-weight: bold;
  padding-bottom: 35px;
  &:hover {
    color: ${(props) => props.theme.colors.hover};
  }
`;
export const LocationPhone = styled.div `
  padding-bottom: 10px;
`;
export const LocationName = styled.h5 `
  font-size: 15px;
  line-height: 21px;
  margin: 0;
`;
export const Phone = styled.a `
  font-size: 15px;
  text-decoration: underline;
  margin: 0;
  &:hover {
    color: ${(props) => props.theme.colors.hover};
  }
`;
