import styled from 'styled-components';
import { TabContent } from '@@Components/Tabs/styles';
export const CatalogPreviewContainer = styled.div `
  ${TabContent} {
    padding-top: 25px;
  }
`;
export const CatalogPreviewLabel = styled.label `
  font-size: 16px;
`;
export const CatalogProductsList = styled.ul `
  display: flex;
  align-items: center;
  li {
    margin-right: 30px;
    button {
      margin: 0;
      padding: 0;
    }
  }
`;
export const CategoryProductListEntry = styled.li `
  font-size: 16px;
  > * {
    ${(props) => props.active &&
    `
      color: ${props.theme.colors.primary};
      font-weight: 600;
    `}
    &:hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;
export const CatalogSeeAllProductsButton = styled.a `
  display: flex;
  align-items: center;
  font-weight: 600;
  padding: 10px 0;
  margin-bottom: 50px;
  svg {
    margin-left: 10px;
  }
  &:hover {
    color: ${(props) => props.theme.colors.primary};
    svg {
      fill: ${(props) => props.theme.colors.primary};
    }
  }
`;
export const CatalogPreviewProductCard = styled.div `
  display: flex;
  flex-flow: row nowrap;
  ${(props) => props.theme.mixins.mobile `
    flex-flow: column;
  `}
`;
export const CatalogPreviewProductImage = styled.div `
  flex-grow: 2;
  min-width: 50%;
`;
export const CatalogPreviewProductInfo = styled.div `
  flex-grow: 2;
  padding: 15px 70px;
  ${(props) => props.theme.mixins.mobile `
    padding: 15px 0;
  `}
`;
export const CatalogProductPriceWrapper = styled.div `
  display: flex;
  align-items: center;
  padding: 10px 0 25px 0;
`;
export const CatalogProductPrice = styled.h1 `
  color: ${(props) => props.theme.colors.primary};
  margin: 0 10px;
`;
export const CatalogPreviewProductSpecs = styled.ul `
  margin-bottom: 20px;
  li {
    &:not(:last-child) {
      border-bottom: 1px solid #bababa;
    }
    padding: 15px 0;
  }
`;
