import { __awaiter } from "tslib";
import React, { useEffect, useState } from 'react';
import { DescriptionContainer, DescriptionText, InformationContainer, PriceLine, ProductImage, ProductLongTileContainer, TextContainer, Title, Text, PriceLabel, TextLine, StyledButton, TextLineInner, AlternativeTitle, TextLinesWrapper, InformationInnerContainer, } from '@@Components/ProductLongTile/styled';
import { ThumbnailUtils } from '@grupakmk/libstorefront';
import { useLang } from '@@Hooks/useLang';
import { getProductLink } from '@@Storefront/utils/get-product-link';
import { useLibertyProduct } from '@@Hooks/useLibertyProduct';
import { getFormattedFromPrice } from '@@Helpers/getFormattedFromPrice';
import { Link } from 'react-router-dom';
export const ProductLongTile = ({ product, }) => {
    const { translate } = useLang();
    const [fromPrice, setFromPrice] = useState();
    const { getSpecification } = useLibertyProduct();
    const [specification, setSpecification] = useState({});
    const getProductImage = (product) => {
        return ThumbnailUtils.getImageSrc({
            image: product === null || product === void 0 ? void 0 : product.image,
            width: 500,
            height: 426,
            entity: '',
        });
    };
    useEffect(() => {
        if (product) {
            (() => __awaiter(void 0, void 0, void 0, function* () {
                const spec = yield getSpecification(product, ['insurance'], '-');
                setSpecification(spec);
            }))();
            setFromPrice(getFormattedFromPrice(product));
        }
    }, [product]);
    return (React.createElement(React.Fragment, null, product && (React.createElement(ProductLongTileContainer, null,
        React.createElement(Link, { to: getProductLink(product, '') },
            React.createElement(ProductImage, { url: getProductImage(product) })),
        React.createElement(TextContainer, null,
            React.createElement(Link, { to: getProductLink(product, '') },
                React.createElement(Title, null, product === null || product === void 0 ? void 0 : product.name)),
            React.createElement(DescriptionContainer, null, product.short_description && (React.createElement(React.Fragment, null,
                React.createElement(DescriptionText, { dangerouslySetInnerHTML: {
                        __html: product === null || product === void 0 ? void 0 : product.short_description,
                    } }))))),
        React.createElement(InformationContainer, null,
            React.createElement(InformationInnerContainer, null,
                React.createElement(Link, { to: getProductLink(product, '') },
                    React.createElement(AlternativeTitle, null, product === null || product === void 0 ? void 0 : product.name)),
                React.createElement(PriceLine, null,
                    React.createElement(Text, null, translate('common.from')),
                    React.createElement(PriceLabel, null, fromPrice ? fromPrice === null || fromPrice === void 0 ? void 0 : fromPrice.price : React.createElement("span", null, "\u2013")),
                    React.createElement(Text, null, `/ ${translate('common.day')}`)),
                React.createElement(TextLinesWrapper, null, specification &&
                    Object.values(specification).map((element, index) => {
                        var _a;
                        return (React.createElement(TextLine, { key: index },
                            React.createElement(TextLineInner, null,
                                `${element.label}: `,
                                React.createElement("b", null, (_a = element.value) === null || _a === void 0 ? void 0 : _a.label))));
                    }))),
            React.createElement(Link, { to: getProductLink(product, '') },
                React.createElement(StyledButton, null, translate('actions.book'))))))));
};
