import React, { useEffect, useRef } from 'react';
import { StaticPageContent } from './styles';
export const StaticPageContentStyles = ({ children }) => {
    const contentRef = useRef(null);
    /* set target attrbute in links because these cannot be set in Magento admin panel */
    /* for links beginning with http open in external window */
    useEffect(() => {
        const check = () => {
            if (contentRef.current) {
                clearInterval(checkInterval);
                [
                    ...contentRef.current.querySelectorAll('a[href]:not([target])'),
                ].forEach((link) => {
                    link.setAttribute('target', link.getAttribute('href').indexOf('http') === 0
                        ? '_blank'
                        : '_self');
                });
                const videoWrapperClass = 'video-wrapper';
                // wrap Youtube embeds in responsive elements
                [
                    ...contentRef.current.querySelectorAll('iframe[width][height][src*="youtube"]'),
                ].forEach((iframe) => {
                    if (!iframe.parentNode.classList.contains(videoWrapperClass)) {
                        const centered = iframe.parentNode.style.textAlign === 'center';
                        const maxWidth = Math.min(1180, iframe.width);
                        const reuse = iframe.parentNode.childElementCount === 1;
                        const wrapper = reuse
                            ? iframe.parentNode
                            : document.createElement('div');
                        wrapper.classList.add(videoWrapperClass);
                        wrapper.dataset.rwd = true;
                        if (reuse) {
                            wrapper.dataset.reused = 'true';
                        }
                        const marginVal = centered ? 'auto' : '0';
                        wrapper.setAttribute('style', `
              max-width: ${maxWidth}px;
              margin-left: ${marginVal};
              margin-right: ${marginVal};
              --aspect-ratio: ${iframe.height / iframe.width};
            `);
                        if (!reuse) {
                            iframe.parentNode.insertBefore(wrapper, iframe.nextSibling);
                            wrapper.appendChild(iframe);
                        }
                    }
                });
            }
        };
        const checkInterval = setInterval(check, 1000);
        return () => {
            if (checkInterval) {
                clearInterval(checkInterval);
            }
        };
    }, [contentRef === null || contentRef === void 0 ? void 0 : contentRef.current]);
    return React.createElement(StaticPageContent, { ref: contentRef }, children);
};
