import styled from 'styled-components';
export const CountersRowContainer = styled.div `
  display: flex;
  justify-content: space-between;
  @media (max-width: 1200px) {
    justify-content: space-around;
  }
  ${(props) => props.theme.mixins.mobile(`
    flex-wrap: wrap;
    row-gap: 40px;
    > * {
      width: 40%;
    }
  `)}
  @media (max-width: 600px) {
    > * {
      width: 100%;
    }
  }
`;
