import styled from 'styled-components';
export const DropdownWrapper = styled.div `
  position: relative;
`;
export const DropdownTriggerContainer = styled.div `
  display: flex;
  align-items: center;
`;
export const DropdownPlaceholderWrapper = styled.div `
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors.primary};
    fill: ${(props) => props.theme.colors.primary};
  }
`;
export const DropdownPlaceholder = styled.div `
  margin-right: 10px;
  font-weight: 600;
`;
export const DropdownContent = styled.div `
  display: flex;
  flex-flow: column;
  position: absolute;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  z-index: 2;
  padding: 10px 13px;
  border: 1px solid #d8d8d8;
  right: 0px;
`;
export const DropdownOptionWrapper = styled.div `
  font-size: 15px;
  white-space: nowrap;
  padding: 5px 0;
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: 1px solid #cbcbcb;
  }
  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`;
