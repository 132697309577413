import styled from 'styled-components';
export const StaticPageHeaderImageWrapper = styled.div `
  background-image: url(${(props) => props.background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 24.33vw;
  display: flex;
  align-items: center;
  width: 100%;
  color: ${(props) => props.color || '#FFF'};
  padding: 0 150px;
  @media (min-width: 1440px) {
    min-height: 350px;
  }
  ${(props) => props.theme.mixins.mobile(`
    padding: 0 15px;
  `)}
`;
export const StaticPageContentContainer = styled.div `
  max-width: 520px;

  ${(props) => props.theme.mixins.mobile(`
    max-width: 100%;
  `)}
`;
export const StaticPageSubtitle = styled.div `
  font-size: 16px;
  padding-bottom: 10px;
`;
export const StaticPageTitle = styled.h1 `
  white-space: pre-wrap;
`;
