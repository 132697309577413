import React from 'react';
import { StaticPageHeaderImageWrapper, StaticPageContentContainer, StaticPageSubtitle, StaticPageTitle, } from '@@Pages/StaticPage/StaticPageHeaderImage/styled';
import { LimitedContainer } from '@@Components/LimitedContainer';
export const StaticPageHeaderImage = (props) => {
    var _a, _b;
    return (React.createElement(StaticPageHeaderImageWrapper, { background: props.image, color: props.textColor },
        React.createElement(LimitedContainer, { wide: true },
            React.createElement(StaticPageContentContainer, null,
                React.createElement(StaticPageSubtitle, null, props.subtitle),
                props.firstLineWords ? (React.createElement(React.Fragment, null,
                    React.createElement(StaticPageTitle, null, (_a = props.title) === null || _a === void 0 ? void 0 : _a.split(' ').slice(0, props.firstLineWords).join(' ')),
                    React.createElement(StaticPageTitle, null, (_b = props.title) === null || _b === void 0 ? void 0 : _b.split(' ').slice(props.firstLineWords).join(' ')),
                    ' ')) : (React.createElement(StaticPageTitle, null, props.title))))));
};
