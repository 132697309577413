import styled from 'styled-components';
export const FaqContainer = styled.div `
  display: flex;
  flex-direction: column;
`;
export const FaqDoubleTextLine = styled.div `
  display: flex;
  flex-direction: column;
`;
export const Heading = styled.p `
  font-size: 16px;
  margin-bottom: 5px;
  ${(props) => props.theme.mixins.mobile(`
    font-size: ${props.mobileFontSize || '14px'};
  `)}
`;
export const Title = styled.h1 `
  font-size: 32px;
  ${(props) => props.theme.mixins.mobile('font-size: 20px;')}
`;
export const FaqColumnsWrapper = styled.div `
  display: flex;
  margin-top: 40px;
  column-gap: 25px;
  ${(props) => props.theme.mixins.mobile(`
    margin-top: 25px;
    flex-direction: column;
    column-gap: 0;
  `)}
`;
