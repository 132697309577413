import styled from 'styled-components';
export const AccessoryGroupSection = styled.section ``;
export const AccessoryGroupsContainer = styled.div `
  ${AccessoryGroupSection} + ${AccessoryGroupSection} {
    margin-top: 25px;
  }
  margin-bottom: 25px;
`;
export const AccessoryGroupTitle = styled.h4 `
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 25px;
`;
export const AccessoryGroupOptionWrapper = styled.li `
  display: flex;
  ${(props) => props.disabled
    ? `
    cursor: default;
    pointer-events: none;
  `
    : `
    cursor: pointer;
  `}
`;
export const AccesoryGroupPrice = styled.div `
  padding-left: 8px;
  white-space: nowrap;
`;
export const AccesoryGroupName = styled.div `
  font-weight: bold;
`;
export const AccesoryGroupOptions = styled.ul `
  ${AccessoryGroupOptionWrapper} + ${AccessoryGroupOptionWrapper} {
    margin-top: 8px;
  }
`;
export const AccessoryGroupCheckboxWrapper = styled.div `
  width: 18px;
  height: 18px;
  margin-right: 10px;
  pointer-events: none;
`;
export const AccessoryGroupInnerWrapper = styled.div `
  > * {
    display: inline;
  }
`;
