import { __awaiter } from "tslib";
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLang } from '@@Hooks/useLang';
import { Select } from '@@Components/Select/Select';
import { Datetimepicker } from '@@Components/Datetimepicker';
import { Button } from '@@Components/Buttons/Button';
import dayjs from 'dayjs';
import { notify } from '@@Helpers/notifications';
import { NotificationType } from '@grupakmk/libstorefront';
import LSF from '@@Storefront';
import { useSelector } from 'react-redux';
import { attributesSelector } from '@@Redux/selectors/attributes.selectors';
import { ButtonWrapper, FormTitle, FormWrapper, InnerFormWrapper, } from './styled';
export const RentalForm = (props) => {
    const { handleSubmit, errors, control, setValue } = useForm({
        mode: 'onChange',
    });
    const { translate } = useLang();
    const attributes = useSelector(attributesSelector);
    const [isPending, setIsPending] = useState(false);
    const [checkInDate, setCheckInDate] = useState(null);
    const [locationsSelected, setLocationsSelected] = useState(false);
    const [firstLocation, setFirstLocation] = useState(null);
    const [secondLocation, setSecondLocation] = useState(null);
    const [pickupOptions, setPickupOptions] = useState([]);
    const [returnOptions, setReturnOptions] = useState([]);
    const getPickupCity = () => {
        var _a, _b, _c;
        try {
            if (props && props.product) {
                if (((_a = props === null || props === void 0 ? void 0 : props.product.pickup_city) === null || _a === void 0 ? void 0 : _a.length) === 0) {
                    throw new Error('product does not have proper pickup_city attribute!');
                }
                const attributes = LSF.getState().attribute.list_by_code;
                if (!attributes) {
                    throw new Error('Cannot find attributes!');
                }
                const pickupCityAttribute = attributes['pickup_city'];
                if (!(((_b = pickupCityAttribute === null || pickupCityAttribute === void 0 ? void 0 : pickupCityAttribute.options) === null || _b === void 0 ? void 0 : _b.length) > 0)) {
                    throw new Error('Cannot find pickup_city attribute options!');
                }
                const allPickupCity = pickupCityAttribute.options
                    .map((option) => ({ value: option.value, label: option.label }))
                    .filter((option) => option.value &&
                    option.label &&
                    option.value !== '' &&
                    option.label !== '');
                if (!(allPickupCity.length > 0)) {
                    throw new Error('No available pickup cities!');
                }
                const productCities = (_c = props === null || props === void 0 ? void 0 : props.product) === null || _c === void 0 ? void 0 : _c.pickup_city.map((productCity) => {
                    return allPickupCity.find((city) => !Number.isNaN(city.value) &&
                        Number(city.value) === Number(productCity));
                }).filter((city) => city);
                if (!(productCities.length > 0)) {
                    throw new Error('No available product cities!');
                }
                const finalProductCities = productCities.map((city) => ({
                    value: city.label,
                    label: city.label,
                }));
                const finalAllPickupCity = allPickupCity.map((city) => ({
                    value: city.label,
                    label: city.label,
                }));
                setPickupOptions(finalProductCities);
                setReturnOptions(finalAllPickupCity);
            }
        }
        catch (error) {
            console.log('Error while getting pickup city for product:', error);
        }
    };
    const handleFirstLocation = (e) => {
        setFirstLocation(e.value);
        return true;
    };
    const handleSecondLocation = (e) => {
        setSecondLocation(e.value);
        return true;
    };
    const onSubmitDefault = (data) => __awaiter(void 0, void 0, void 0, function* () {
        if (props.onSubmit) {
            setIsPending(true);
            try {
                yield props.onSubmit(data);
            }
            catch (error) {
                notify(error.toString().slice(7), NotificationType.WARN);
            }
            setIsPending(false);
        }
    });
    const handleFirstDate = (e) => {
        setValue('date_to', null);
        const startDay = new Date(e.split(' ')[0].split('/')[0], e.split(' ')[0].split('/')[1], e.split(' ')[0].split('/')[2]);
        const newDate = new Date();
        newDate.setDate(startDay.getDate() + 1);
        setCheckInDate(`${newDate.getFullYear()}/${(newDate.getMonth() + 1).toString().length > 1
            ? newDate.getMonth() + 1
            : `0${newDate.getMonth() + 1}`}/${newDate.getDate().toString().length > 1
            ? newDate.getDate()
            : `0${newDate.getDate()}`}`);
        return true;
    };
    useEffect(() => {
        if (props.product)
            getPickupCity();
    }, [props.product, attributes]);
    useEffect(() => {
        if (props.setDifferentPlace && firstLocation && secondLocation) {
            if (firstLocation !== secondLocation) {
                notify(translate('messages.checkout_add_different_place'), NotificationType.SUCCESS);
                props.setDifferentPlace(true);
            }
            else {
                if (locationsSelected) {
                    notify(translate('messages.checkout_remove_different_place'), NotificationType.SUCCESS);
                }
                props.setDifferentPlace(false);
            }
            setLocationsSelected(true);
        }
    }, [firstLocation, secondLocation]);
    return (pickupOptions && (React.createElement(FormWrapper, null,
        React.createElement("form", { onSubmit: handleSubmit(onSubmitDefault), noValidate: true, autoComplete: "off" },
            React.createElement(FormTitle, null, translate('forms.rental_form')),
            React.createElement(InnerFormWrapper, null,
                React.createElement(Controller, { control: control, name: "city_get", rules: { required: translate('validation.required') }, render: ({ ref, onBlur, onChange, value }) => (React.createElement(Select, { value: value, label: translate('checkout.vehicle_pickup_place'), placeholder: translate('checkout.vehicle_pickup_place'), options: pickupOptions || [], required: true, error: errors['city_get'], ref: ref, onBlur: onBlur, onChange: (e) => handleFirstLocation(e) && onChange(e.value) })) }),
                React.createElement(Controller, { control: control, name: "city_return", rules: { required: translate('validation.required') }, render: ({ ref, onBlur, onChange, value }) => (React.createElement(Select, { value: value, label: translate('checkout.vehicle_return_place'), placeholder: translate('checkout.vehicle_return_place'), options: returnOptions || [], required: true, error: errors['city_return'], ref: ref, onBlur: onBlur, onChange: (e) => handleSecondLocation(e) && onChange(e.value) })) }),
                React.createElement(Controller, { control: control, name: "date_from", rules: { required: translate('validation.required') }, render: ({ ref, onBlur, onChange, value }) => (React.createElement(Datetimepicker, { label: translate('checkout.pickup_date'), placeholder: `${translate('common.from')}`, required: true, ref: ref, error: errors['date_from'], value: value, onBlur: onBlur, onChange: (e) => handleFirstDate(e) && onChange(e), minimalDate: dayjs().format('YYYY/MM/DD'), blockSunday: true })) }),
                React.createElement(Controller, { control: control, name: "date_to", rules: { required: translate('validation.required') }, render: ({ ref, onBlur, onChange, value }) => (React.createElement(Datetimepicker, { label: translate('checkout.return_date'), placeholder: `${translate('common.to')}`, required: true, ref: ref, error: errors['date_to'], value: value, onBlur: onBlur, onChange: onChange, minimalDate: checkInDate || dayjs().format('YYYY/MM/DD'), blockSunday: true })) })),
            React.createElement(ButtonWrapper, null,
                React.createElement(Button, { loading: isPending, appearance: "dark" }, translate('actions.make_a_reservation')))))));
};
