import styled from 'styled-components';
export const PaginationWrapper = styled.div `
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 14px;
`;
export const PaginationButtonWrapper = styled.div `
  padding: 5px 10px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  line-height: 20px;
`;
export const PaginationInput = styled.input `
  font-weight: 600;
  font-size: 15px;
  margin-right: 5px;
  padding: 0;
  width: 10px;
  outline: none;
  text-align: center;
`;
export const PaginationCountInfo = styled.div ``;
