import styled from 'styled-components';
import Button from '@@Components/Buttons/Button';
export const CheckoutContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 15px 35px 45px 35px;
  ${(props) => props.theme.mixins.mobile('padding: 25px 15px 35px 15px;')}
  @media (max-width: 750px) {
    padding: 15px 0 45px 0;
  }
`;
export const CheckoutInnerContainer = styled.div `
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 1440px;
  ${(props) => props.theme.mixins.mobile(`
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  `)}
`;
export const LeftColumn = styled.div `
  width: 670px;
  padding-bottom: 60px;
  @media (max-width: 1400px) {
    width: 45vw;
  }
  ${(props) => props.theme.mixins.mobile('width: 100%;')}
  @media (max-width: 750px) {
    padding: 0 15px;
  }
`;
export const RightColumn = styled.div `
  width: 580px;
  border: 1px solid ${(props) => props.theme.input.borderColors.focus};
  padding: 30px 25px;
  height: inherit;
  @media (max-width: 1400px) {
    width: 40vw;
  }
  ${(props) => props.theme.mixins.mobile('width: 100%;')}
  @media (max-width: 750px) {
    border: none;
    padding: 0 15px;
  }
`;
export const TileWrapper = styled.div `
  display: flex;
  flex-direction: column;
`;
export const HeadingTitle = styled.h4 `
  ${(props) => props.theme.mixins.mobile('font-size: 20px;')}
`;
export const Title = styled.h4 `
  ${(props) => props.paddingBottom &&
    `
    padding-bottom: ${props.paddingBottom};
  `}
  ${(props) => props.theme.mixins.mobile(`
    ${props.mobilePaddingBottom &&
    `
      padding-bottom: ${props.mobilePaddingBottom};
    `}
  `)}
`;
export const TileImage = styled.img `
  width: 207px;
  height: 128px;
  object-fit: cover;
  margin-top: 30px;
  @media (max-width: 550px) {
    align-self: center;
  }
`;
export const Subtitle = styled.h5 `
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.input.borderColors.focus};
  padding-bottom: 15px;
  margin-top: 45px;
  margin-bottom: 25px;
  ${(props) => props.theme.mixins.mobile(`
    font-size: 16px;
    margin-top: 35px;
  `)}
`;
export const DetailsSection = styled.div `
  ${(props) => props.paddingBottom &&
    `
    padding-bottom: ${props.paddingBottom};
  `}
  ${(props) => props.theme.mixins.mobile(`
    ${props.mobilePaddingBottom &&
    `
      padding-bottom: ${props.mobilePaddingBottom};
    `}
  `)}
`;
export const Text = styled.p `
  ${(props) => props.fontWeight && `font-weight: ${props.fontWeight};`}
  ${(props) => props.paddingLeft && `padding-left: ${props.paddingLeft};`}
  ${(props) => props.paddingRight && `padding-right: ${props.paddingRight};`}
  line-height: 22px;
  margin-bottom: ${(props) => props.marginBottom || '10px'};
  ${(props) => props.textAlign && `text-align: ${props.textAlign};`}
  ${(props) => props.noWrap && 'white-space: nowrap;'}
`;
export const TextRow = styled.div `
  display: flex;
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  align-items: ${(props) => props.alignItems || 'center'};
`;
export const DetailsFooter = styled.div `
  margin: 20px 0;
  padding-top: 15px;
  border-top: 1px solid ${(props) => props.theme.input.borderColors.focus};
  @media (max-width: 750px) {
    padding-top: 10px;
    margin-top: 5px;
  }
`;
export const BigPrice = styled.h1 `
  font-size: 28px;
  line-height: 34px;
  color: ${(props) => props.theme.colors.primary};
  ${(props) => props.theme.mixins.mobile('font-size: 24px;')}
`;
export const SubmitButton = styled(Button) `
  width: 250px;
  @media (max-width: 750px) {
    width: 100%;
  }
`;
export const ColumnDivider = styled.div `
  border-top: 1px solid ${(props) => props.theme.input.borderColors.focus};
  width: 100%;
  margin: 40px 0 25px 0;
  @media (min-width: 751px) {
    display: none;
  }
`;
export const NoResultsWrapper = styled.div `
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`;
