import styled from 'styled-components';
export const NavbarWrapper = styled.div `
  background: #fff;
  top: 0;
  z-index: 50;
  position: sticky;

  @media (min-width: 319px) and (max-width: 882px) {
    position: fixed;
    width: 100%;
  }

  &.is-stuck {
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.15);
  }
`;
export const NavbarDesktopWrapper = styled.div `
  height: 50px;
  padding: 0 36px;
  background-color: #fff;
  position: relative;
  z-index: 15;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.02);
`;
export const NavbarDesktopInnerContainer = styled.div `
  display: flex;
  align-items: center;
  height: 50px;
  max-width: 1440px;
  margin: 0 auto;
`;
export const LogoWrapper = styled.div `
  cursor: pointer;
  padding-right: 40px;
`;
export const NavbarLinksWrapper = styled.ul `
  display: flex;
`;
export const NavbarLinkWrapper = styled.li `
  color: #000000;
  padding: 0 30px 0 0;
  cursor: pointer;
  white-space: nowrap;
  svg {
    display: inline;
    margin-left: 8px;
  }
  ${(props) => !props.is_active &&
    `
    
    `}
`;
export const NavbarLink = styled.a `
  font-weight: ${(props) => (props.expanded ? '700' : '400')};
  &:hover {
    color: ${(props) => props.theme.colors.hover};
    svg {
      fill: ${(props) => props.theme.colors.hover};
    }
  }
`;
export const NavbarDropdown = styled.div `
  width: 100%;
  height: 380px;
  background-color: #fff;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 3;
  display: flex;
`;
export const ContactDropdownWrapper = styled(NavbarDropdown) `
  padding: 30px 0;
`;
export const ContactListWrapper = styled.div `
  flex-grow: 2;
  padding: 40px 0;
`;
export const ContactPreviewThumbnail = styled.div `
  min-width: 40%;
  height: 100%;
  background-color: #fff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${(props) => props.background});
`;
export const ContactListContainer = styled.ul `
  margin-top: 25px;
`;
export const ContactLoaderWrapper = styled.div `
  width: 25px;
`;
export const StoreLocationWrapper = styled.li `
  font-size: 16px;
  font-weight: ${(props) => (props.highlighted ? '800' : '500')};
  margin-bottom: 20px;
`;
export const BrandLinkWrapper = styled.li `
  font-weight: ${(props) => (props.highlighted ? '800' : '500')};
  font-size: 16px;
  margin-bottom: 20px;
  min-width: 160px;
  a {
    cursor: pointer;
  }
`;
export const BrandPanelWrapper = styled.ul `
  margin-top: 15px;
  > * {
    font-size: 14px;
  }
`;
export const ContactPanelWrapper = styled.div ``;
export const DrawerContent = styled.div `
  padding: 21px 16px 0;
  flex-grow: 2;
  height: calc(100% - 40px);
  overflow: auto;
  margin-top: 40px;
`;
export const CategoryLogo = styled.div `
  background-image: url(${(props) => props.background});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 90px;
  min-width: 90px;
  height: 50px;
  margin-bottom: 20px;
  margin-top: 4px;
`;
export const DrawerHeader = styled.div `
  position: absolute;
  top: 0;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 700;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
`;
export const HamburgerWrapper = styled.div `
  position: absolute;
  left: 15px;
  display: flex;
  align-items: center;
  > .hamburger {
    transform: scale(0.8);
  }
`;
export const IconWrapper = styled.div `
  padding: 12px 15px;
`;
export const MobileNavbarLinkWrapper = styled.ul `
  color: #000000;
  padding: 0 0 20px 0;
  cursor: pointer;
  font-weight: 800;
  svg {
    display: inline;
    margin-left: 8px;
  }
`;
export const NavbarMobileContainer = styled.div `
  padding: 0 15px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.1);
`;
export const NavbarMobileWrapper = styled.div `
  height: 100%;
  display: flex;
  flex-flow: column;
`;
export const NavigationPanelsContent = styled.div `
  &:not(:last-child) {
    border-bottom: 1px solid #bababa;
  }
  margin-bottom: 20px;
`;
export const StoreLocationMobile = styled.div `
  font-weight: 400;
  font-size: 14px;
  margin: 10px 0;
`;
export const MobileNavbarLink = styled.div ``;
