import styled from 'styled-components';
import { Button } from '@@Components/Buttons/Button';
export const ProductLongTileContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 250px;
  width: 400px;
  border: 1px solid ${(props) => props.theme.input.borderColors.default};
  padding: 5px 10px 10px;
  margin-bottom: 10px;
  @media screen and (max-width: 450px) {
    width: 350px;
  }
  @media screen and (max-width: 390px) {
    width: 290px;
  }
`;
export const TopContainer = styled.div `
  display: flex;
`;
export const TitlePriceContainer = styled.div `
  padding-left: 10px;
  width: 250px;
  @media screen and (max-width: 450px) {
    width: 200px;
  }
  @media screen and (max-width: 390px) {
    width: 150px;
  }
`;
export const BottomContainer = styled.div `
  display: flex;
  flex-direction: column;
`;
export const ProductImage = styled.div `
  background-image: url('${(props) => props.url}');
  width: 120px;
  height: 80px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
export const Title = styled.h3 `
  white-space: nowrap;
  overflow: hidden;
  max-width: 95%;
  text-overflow: ellipsis;
  font-size: 14px;
`;
export const PriceLine = styled.div `
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  height: 30px;
  margin-bottom: 5px;
`;
export const PriceLabel = styled.h1 `
  font-size: 30px;
  padding: 0 10px 0 5px;
  color: #d9000d;
  margin: 0;
  font-size: 16px;
`;
export const Text = styled.p `
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 8px;
  margin: 0;
  font-size: 14px;
`;
export const TextLine = styled.div `
  padding: 10px 0;
  border-bottom: 1px solid ${(props) => props.theme.input.borderColors.default};
  &:last-child {
    border: none;
  }
`;
export const TextLineInner = styled.p `
  margin: 0;
  overflow: hidden;
  max-width: 95%;
  text-overflow: ellipsis;
`;
export const StyledButton = styled(Button) `
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const InnerContainer = styled.div ``;
export const TextLinesWrapper = styled.div ``;
export const InformationInnerContainer = styled.div ``;
